


import React, { useState, useEffect } from 'react'

import { Box, Button, Card, CardContent, IconButton, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material'


import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import DataTable from 'react-data-table-component';
import moment from 'moment-timezone';


import useResponseViewer from './hook-components/useResponseViewer';
import useRetryRequest from './hook-components/useRetryRequest';
import useInputViewer from './hook-components/useInputViewer';
import useVortexTransactionViewer from './hook-components/useVortexTransactionViewer';
import { getAllVortexTransactionsByDaterange } from '../../data/remote/vortex';
import { handleVortexRequest } from '../../functions/VortexGcashFunctions';
import CustomLoader from '../../../../core/CustomLoader';
import useSparkleSnackbar from '../../../../core/useSparkleSnackbar';


import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import useCustomerDetailsViewer from './hook-components/useCustomerDetailsViewer';



const AllVortexTransactions = (props) => {


  // STATES
  const [data, setdata] = useState([])

  const [renderData, setRenderData] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const [refresh, setRefresh] = useState(false)

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

  const [selectedRow, setSelectedRow] = useState(null)

  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

  var date = new Date();


  const [dateRangeSelection, setdateRangeSelection] = useState([
    {
      startDate: new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7),
      endDate: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59),
      key: 'selection',
    }
  ])


  //Handlers
  const moreMenuOpen = Boolean(menuAnchorEl);


  const handleMoreMenuClick = (event, row) => {
    setSelectedRow(row)
    setMenuAnchorEl(event.currentTarget);

  };

  const handleMoreMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleDaterangeSelect = (range) => {


    let newDateRange = {
      startDate: new Date(range.startDate.getFullYear(), range.startDate.getMonth(), range.startDate.getDate()),
      endDate: new Date(range.endDate.getFullYear(), range.endDate.getMonth(), range.endDate.getDate(), 23, 59, 59),
      key: 'selection',
    }




    setdateRangeSelection([newDateRange])


    console.log(dateRangeSelection);
  }

  const handleGetDataRequest = () => {
    setIsLoading(true)
    setdata([])

    getAllVortexTransactionsByDaterange({ startDate: dateRangeSelection[0].startDate, endDate: dateRangeSelection[0].endDate })

      .then((response) => {
        if (response?.status === 200) {
          response.json()
            .then(result => {
              setdata(result)
              setIsLoading(false)
            })
        } else {
          setIsLoading(false)
          throw Error("Failed getting vortex transactions")
        }
      })
      .catch((error) => {
        setIsLoading(false)
        throw error
      })
  }




  // HOOK COMPONENTS
  const { showResponseViewer, closeResponseViewer, ResponseDialog } = useResponseViewer()

  const { showInputViewer, closeInputViewer, InputDialog } = useInputViewer()

  const { showRetryRequest, closeRetryRequest, RetryDialog } = useRetryRequest()

  const { showVortexTransactionViewer, closeVortexTransactionViewer, VortexTransactionViewerDialog } = useVortexTransactionViewer()

  const { showCustomerDetailsViewer, closeCustomerDetailsViewer, CustomerDetailsViewer } = useCustomerDetailsViewer()

  const { showSnackbar, closeSnackbar, SparkleSnackBar } = useSparkleSnackbar("bottom", "center")





  const columns = [
    {
      id: 'createdAt',
      name: 'Created At',
      selector: row => row.createdAt,
      sortable: true,
      cell: row => <span>{moment(row.createdAt
      ).tz('Asia/Dubai').format("YYYY MMMM DD - hh:mm:ss a")}
      </span>,
      grow: 2,
    },
    {
      id: "type",
      name: 'Type',
      selector: row => row.type,
    },
    {
      id: "id",
      name: 'ID',
      grow: 2,
      selector: row => (
        <Button onClick={() => {
          navigator.clipboard.writeText(row._id)
          showSnackbar("Text copied", "success")
        }}>
          {row._id}
        </Button>
      ),
    },
    {
      id: "referenceNumber",
      name: 'Ref no',
      selector: row => (
        <Button onClick={() => {
          navigator.clipboard.writeText(row.referenceNumber)
          showSnackbar("Text copied", "success")
        }}>
          {row.referenceNumber}
        </Button>
      ),
    },
    {
      id: "cashier",
      name: 'Cashier',
      selector: row => (
        <Button onClick={() => {
          navigator.clipboard.writeText(row?.userId?.name)
          showSnackbar("Text copied", "success")
        }}>
          {row?.userId?.name || "Unavailable"}
        </Button>
      ),
    },
    {
      id: "store",
      name: 'Store',
      selector: row => row.store.name,
      sortable: true,
    },
    {
      id: "status",
      name: 'Status',
      selector: row => row.status,
      sortable: true,
    },
    {
      id: "currencyToPhp",
      name: 'CURRENCY TO PHP',
      selector: row => row.currencyToPhp,
      cell: row => (
        <span>{
          `${row.currencyToPhp} PHP`
        }
        </span>),
      sortable: true,
    },
    {
      id: "convenienceFee",
      name: 'Conv Fee',
      selector: row => row.convenienceFee,
      cell: row => (
        <span>{
          `${row.convenienceFee} ${row.currencySymbol}`
        }
        </span>),
      sortable: true,
    },
    {
      id: "totalAmount",
      name: 'Total',
      selector: row => row.totalAmount,
      cell: row => (
        <span>{
          `${row.totalAmount} ${row.currencySymbol}`
        }
        </span>),
      sortable: true,
    },
    // {
    //   id: "paymentId",
    //   name: 'Payment ID',
    //   selector: row => row.paymentId,
    // },
    // {
    //   id: "paymentMethod",
    //   name: 'Method',
    //   selector: row => row.paymentMethod,
    //   sortable: true,

    // },

    // {
    //   id: "actionButton",
    //   name: 'Confirm GCASH',
    //   selector: row => (
    //     <div>
    //       {
    //         <Button disabled={row.paymentId !== 'Awaiting for GCash Payment'} onClick={
    //           () => {
    //             let gCashTransactionNum = prompt("Enter GCash transaction/reference number:");

    //             if (gCashTransactionNum) {
    //               handleVortexRequest(row, gCashTransactionNum);
    //             }

    //             setRefresh(!refresh)

    //           }

    //         }>Confirm</Button>

    //       }
    //     </div>
    //   ),
    // },
    // {
    //   name: 'Retry Request',
    //   selector: row => (
    //     <Button onClick={() => {
    //       showRetryRequest(row)
    //     }}>Retry</Button>
    //   ),
    // },
    {
      name: 'Options',
      selector: row => (
        <Box>
          <IconButton
            id="basic-button"
            aria-controls={moreMenuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={moreMenuOpen ? 'true' : undefined}
            onClick={(event) => {
              handleMoreMenuClick(event, row)
            }}
          >
            <MoreHorizIcon />
          </IconButton>

        </Box>
      ),
    },

  ];

  useEffect(() => {

    getAllVortexTransactionsByDaterange({ startDate: dateRangeSelection[0].startDate.toLocaleDateString(), endDate: dateRangeSelection[0].endDate })

      .then((response) => {
        if (response?.status === 200) {
          response.json()
            .then(result => {
              setdata(result)
              setIsLoading(false)
            })
        } else {
          setIsLoading(false)
          throw Error("Failed getting vortex transactions")
        }
      })
      .catch((error) => {
        setIsLoading(false)
        throw error
      })


  }, [refresh])


  useEffect(() => {

    setRenderData(data)

  }, [data])



  return (
    <Box>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Stack direction={"row"} spacing={2}>
              <Stack>
                <DateRangePicker
                  // onChange={item => setdateRangeSelection([item.selection])}
                  onChange={item => handleDaterangeSelect(item.selection)}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={dateRangeSelection}
                  direction="horizontal"
                />
                <Box>
                  <Button variant={"contained"} onClick={() => {
                    handleGetDataRequest()
                  }}>GET DATA</Button>
                </Box>
              </Stack>
              <TextField label="Transaction ID"
                onChange={(e) => {
                  if (data.length > 0) {
                    setResetPaginationToggle(false)

                    let filterData = data.filter(
                      transaction => transaction?._id.includes(e.target.value)
                    )

                    setRenderData(filterData)
                  }

                }} />
              <TextField label="Reference no"
                onChange={(e) => {
                  if (data.length > 0) {
                    setResetPaginationToggle(false)

                    let filterData = data.filter(
                      transaction => transaction?.referenceNumber.includes(e.target.value)
                    )

                    setRenderData(filterData)
                  }

                }} />
              <TextField label="User name"
                onChange={(e) => {
                  if (data.length > 0) {
                    setResetPaginationToggle(false)

                    let filterData = data.filter(
                      transaction => transaction?.userId?.name.includes(e.target.value)
                    )

                    setRenderData(filterData)
                  }

                }} />
            </Stack>
          </CardContent>
        </Card>
        <DataTable
          pagination
          columns={columns}
          data={renderData}
          defaultSortFieldId={'createdAt'}
          defaultSortAsc={false}
          progressPending={isLoading}
          paginationResetDefaultPage={resetPaginationToggle}
          progressComponent={<CustomLoader />}

        />
        <Box>
          <Button variant='contained'
            onClick={() => {
              if (renderData.length > 0) {
                const csvString = [
                  ["Created at", "Type", "ID", "Ref No", "Cashier", "Store", "Status", "1 AED TO PHP", "ConvFee", "Total Amount"],
                  ...renderData.map(item => [
                    item.createdAt,
                    item.type,
                    item._id,
                    item.referenceNumber,
                    item.userId.name,
                    item.store.name,
                    item.status,
                    item.oneAedToPhp,
                    item.convenienceFee,
                    item.totalAmount
                  ])
                ].map(e => e.join(","))
                  .join("\n");

                let csvContent = "data:text/csv;charset=utf-8,"
                  + csvString

                var today = new Date();

                var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", `sandbox_vortex_transactions_${date}.csv`);
                document.body.appendChild(link); // Required for FF

                link.click();
              }

            }}
          >Download CSV</Button>
        </Box>
      </Stack>
      <ResponseDialog />
      <InputDialog />
      <VortexTransactionViewerDialog />
      <CustomerDetailsViewer />
      <RetryDialog onSuccess={() => {
        setRefresh(!refresh)
      }} />
      <Menu
        id="basic-menu"
        anchorEl={menuAnchorEl}
        open={moreMenuOpen}
        onClose={handleMoreMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => {
          handleMoreMenuClose()
          showInputViewer(selectedRow.requestInputPayload)
        }}>User inputs</MenuItem>
        <MenuItem onClick={() => {
          handleMoreMenuClose()
          showResponseViewer(selectedRow.transactionData)
        }}>Initial Response</MenuItem>
        <MenuItem onClick={() => {
          handleMoreMenuClose()
          showVortexTransactionViewer(selectedRow)
        }}>Current Status</MenuItem>
        <MenuItem onClick={() => {
          handleMoreMenuClose()
          showCustomerDetailsViewer(selectedRow?.customer)
        }}>Customer Details</MenuItem>
      </Menu>
      <SparkleSnackBar />
    </Box>
  )
}

export default AllVortexTransactions