import React, { useState, useEffect } from "react";
import Layout from "../core/Layout";
import { Redirect, useHistory  } from 'react-router-dom';
import { signin, authenticate, isAuthenticated } from '../auth';
import { Formik, Form } from 'formik'

import { styled } from '@mui/material/styles';

// import { Link } from "react-router-dom";
import spark from '../assets/vortex_logo_black.png';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
     Link, Button, Card, CardContent, Divider, 
     IconButton, Stack, TextField, Typography, Switch } from "@mui/material";

import CollapseAlert from "../core/CollapseAlert"

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { SketchPicker } from 'react-color'
import HomeIcon from '@mui/icons-material/Home';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import CategoryIcon from '@mui/icons-material/Category';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BarChartIcon from '@mui/icons-material/BarChart';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
    , BarChart, Bar } from 'recharts';

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

const Signin = () => {
    const history = useHistory();

    const [values, setValues] = useState({
        email: "",
        password: "",
        error: "",
        loading: false,
        redirectToReferrer: false
    });
    const [loggedIn, setLoggedIn] = useState(false);
    const [apiKeys, setApiKeys] = useState("");
    const [logo, setLogo] = useState("");
    const [colorPrimary, setColorPrimary] = useState("");
    const [colorSecondary, setColorSecondary] = useState("");
    const [documentation, setDocumentation] = useState("");
    const [activeTab, setActiveTab] = useState("home");
    const [submitMessage, setSubmitMessage] = useState('');
    const { email, password, loading, error, redirectToReferrer } = values;

    const { user } = isAuthenticated();

    const [alertState, setAlertState] = useState({
        open: false,
        severity: "success",
        message: "Message"
    })

    const [showPassword, setShowPassword] = useState(false)

    

    const signInForm = () => (
        <Formik
            initialValues={{
                email: "",
                password: "",
            }}
            onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);
    
                // Hardcoded check for credentials
                if(data.email === "bayadirectclient@gmail.com" && data.password === "eSpApGtO6N") {
                    console.log("Logged in");
                    //history.push('/dashboard')
                    setValues({
                        ...values,
                        redirectToReferrer: true
                    });

                    setLoggedIn(true);
                    setApiKeys("STAGING: "); // Fill this with the actual API keys
                    setDocumentation("Your documentation"); // Fill this with the actual documentation
                } else {
                    setAlertState({
                        open: true,
                        severity: "error",
                        message: "Invalid credentials"
                    });
                }
    
                setSubmitting(false);
            }}
        >
            {
                ({ handleChange, isSubmitting }) => {
                    return (
                        <Form>
                            <Stack spacing={2} textAlign={"center"}>
                                {/* <Stack direction={"row"} justifyContent={"center"}>
                                    <img
                                        className="img-fluid bayadirect"
                                        src="https://sparkle-vortex.imgix.net/bayadirect.jpg?w=300&h=300"
                                        alt="company-logo"
                                        style={{
                                            objectFit: "contain"
                                        }} />
                                </Stack> */}
                                <Typography fontWeight={"bold"} fontSize={25}>Log in</Typography>
                                <Typography fontSize={14} sx={{ color: "gray" }}>Sign in on the internal platform</Typography>
                                <TextField
                                    required
                                    name={"email"}
                                    type={"email"}
                                    label={"Email Address"}
                                    placeholder={"Enter email address"}
                                    onChange={handleChange}
                                />
                                <TextField
                                    required
                                    name={"password"}
                                    type={showPassword ? "text" : "password"}
                                    label={"Password"}
                                    placeholder={"Enter password"}
                                    onChange={handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={() => {
                                                    setShowPassword(!showPassword)
                                                }}
                                            >
                                                {showPassword ? (
                                                    <VisibilityIcon />
                                                ) : (
                                                    <VisibilityOffIcon />
                                                )}
                                            </IconButton>
                                        ),
                                    }}
                                />
                                <CollapseAlert
                                    open={alertState.open}
                                    severity={alertState.severity}
                                    message={alertState.message}
                                    onClose={() => {
                                        setAlertState((prevState) => ({ ...prevState, open: false }))
                                    }}
                                />
                                <Divider />
                                <Button disabled={isSubmitting} variant={"contained"} type={"submit"} sx={{ height: 50 }}>{isSubmitting ? "Please wait..." : "SIGN IN"}</Button>

                            </Stack>
                        </Form>
                    )
                }
            }
        </Formik>
    );

    const redirectUser = () => {
        if (redirectToReferrer) {
            return <Redirect to='/admin/dashboard' />
        }
    }

    useEffect(async () => {
        let response = await fetch(`https://com-sparkle-sparkle.herokuapp.com/api/auth/test-settings`, {
          method: "GET",
    
          headers: {
            Accept: "application/json",
          },
        })
    
        let jsonData = await response.json()
        setLogo(jsonData[0].logo)
        setColorPrimary(jsonData[0].primary)
        setColorSecondary(jsonData[0].secondary)
        console.log({logo})
      }, [])
    
      const handleSignOut = () => {
        // Handle the sign-out logic here
        setLoggedIn(false);
      };

    const handleClick = () => {
    // Simulate some submission logic here
    // For example, you can set a submit message and reset the states

    // Perform validation or other logic before updating the submit message
    // In this example, we'll simply concatenate the three states as the submit message
    const submitMessage = `Primary Color: ${colorPrimary}, Secondary Color: ${colorSecondary}, Logo: ${logo}`;
    setSubmitMessage(submitMessage);

    // Data payload
    const data = {
      _id:"64b9c79d5256767fbeebff58",
      logo: logo,
      primary: colorPrimary,
      secondary: colorSecondary,
    };

    // POST request options
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };

    // Make the POST request
    fetch('https://com-sparkle-sparkle.herokuapp.com/api/auth/updateSettings', requestOptions)
      .then(response => response.json())
      .then(data => {
        // Handle the response data if needed
        console.log(data);
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch
        console.error('Error:', error);
      });

  };

  // Prototype component for the "Home" tab
function Home() {
    const userData = [
      { day: 'Mon', activeUsers: 450, newUsers: 120, returningUsers: 330 },
      { day: 'Tue', activeUsers: 560, newUsers: 180, returningUsers: 380 },
      { day: 'Wed', activeUsers: 720, newUsers: 210, returningUsers: 510 },
      { day: 'Thu', activeUsers: 620, newUsers: 170, returningUsers: 450 },
      { day: 'Fri', activeUsers: 780, newUsers: 230, returningUsers: 550 },
      { day: 'Sat', activeUsers: 850, newUsers: 280, returningUsers: 570 },
      { day: 'Sun', activeUsers: 920, newUsers: 320, returningUsers: 600 },
    ];
  
    const salesData = [
      { product: 'Product A', sales: 120 },
      { product: 'Product B', sales: 90 },
      { product: 'Product C', sales: 180 },
      { product: 'Product D', sales: 150 },
      { product: 'Product E', sales: 200 },
    ];
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Home</Typography>
        <div>
          <Typography variant="h6">User Activity</Typography>
          <LineChart width={500} height={300} data={userData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="activeUsers" stroke="#8884d8" name="Active Users" />
            <Line type="monotone" dataKey="newUsers" stroke="#82ca9d" name="New Users" />
            <Line type="monotone" dataKey="returningUsers" stroke="#ffc658" name="Returning Users" />
          </LineChart>
        </div>
        <div>
          <Typography variant="h6">Top Selling Products</Typography>
          <BarChart width={500} height={300} data={salesData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="product" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="sales" fill="#8884d8" name="Sales" />
          </BarChart>
        </div>
      </div>
    );
  }
  

  // Prototype component for the "Sales Channels"
function SalesChannels() {
    const salesChannelsData = [
      { id: 1, name: 'Online Store', description: 'Manage your online store settings.' },
      { id: 2, name: 'Point of Sale', description: 'Track and manage sales in physical stores.' },
    ];
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Sales Channels</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {salesChannelsData.map((channel) => (
                <TableRow key={channel.id}>
                  <TableCell>{channel.id}</TableCell>
                  <TableCell>{channel.name}</TableCell>
                  <TableCell>{channel.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Add New Sales Channel
        </Button>
      </div>
    );
  }
  
  // Prototype component for the "View Live Data"
  function ViewLiveData() {
    const liveData = {
      activeUsers: 1564,
      revenueToday: '$5,672',
      ordersToday: 238,
    };
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>View Live Data</Typography>
        <div>
          <Typography variant="h6">Active Users: {liveData.activeUsers}</Typography>
          <Typography variant="h6">Revenue Today: {liveData.revenueToday}</Typography>
          <Typography variant="h6">Orders Today: {liveData.ordersToday}</Typography>
        </div>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Refresh Data
        </Button>
      </div>
    );
  }
  
  // Prototype component for the "Security Logs"
  function SecurityLogs() {
    const securityLogsData = [
      { id: 1, event: 'Login Attempt Failed', time: '2023-07-28 12:34:56', user: 'john_doe' },
      { id: 2, event: 'Password Changed', time: '2023-07-27 15:21:43', user: 'mary_smith' },
      // Add more mock data here
    ];
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Security Logs</Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Event</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>User</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {securityLogsData.map((log) => (
                <TableRow key={log.id}>
                  <TableCell>{log.id}</TableCell>
                  <TableCell>{log.event}</TableCell>
                  <TableCell>{log.time}</TableCell>
                  <TableCell>{log.user}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Clear Logs
        </Button>
      </div>
    );
  }

// Prototype component for the "Orders" tab
function Orders() {
    const ordersData = [
      { id: 1, orderNumber: 'ORD-123', customer: 'John Doe', total: 120.5, status: 'Processing' },
      { id: 2, orderNumber: 'ORD-124', customer: 'Jane Smith', total: 89.9, status: 'Delivered' },
      // Add more mock data here
    ];
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Orders</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order Number</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordersData.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>{order.orderNumber}</TableCell>
                  <TableCell>{order.customer}</TableCell>
                  <TableCell>{order.total}</TableCell>
                  <TableCell>{order.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Create New Order
        </Button>
      </div>
    );
  }
  
 // Product component with a BuyButton
function Product({ name, price }) {
    const handleBuyClick = () => {
      // Simulate purchase action
      alert(`You purchased ${name} for ₱${price}.`);
    };
  
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1em' }}>
        <Typography>{name}</Typography>
        <Typography>₱{price}</Typography>
        <Button variant="contained" color="primary" onClick={handleBuyClick}>
          Buy
        </Button>
      </div>
    );
  }
  
  // Prototype component for the "Products" tab
  function Products() {
    const productsData = [
      { id: 1, name: 'Load', price: 10.0 },
      { id: 2, name: 'eGifts', price: 25.0 },
      { id: 3, name: 'Bills Payment', price: 50.0 },
    ];
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Products</Typography>
        <div>
          {productsData.map((product) => (
            <Product key={product.id} name={product.name} price={product.price} />
          ))}
        </div>
      </div>
    );
  }
  
  // Prototype component for the "Customers" tab
  function Customers() {
    const customersData = [
      { id: 1, name: 'John Doe', email: 'john@example.com', orders: 12 },
      { id: 2, name: 'Jane Smith', email: 'jane@example.com', orders: 25 },
      // Add more mock data here
    ];
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Customers</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Orders</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customersData.map((customer) => (
                <TableRow key={customer.id}>
                  <TableCell>{customer.id}</TableCell>
                  <TableCell>{customer.name}</TableCell>
                  <TableCell>{customer.email}</TableCell>
                  <TableCell>{customer.orders}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Add New Customer
        </Button>
      </div>
    );
  }
  
  // Prototype component for the "Transactions" tab
  function Transactions() {
    const transactionsData = [
      { id: 1, date: '2023-07-27', amount: 120.5, type: 'Top Up' },
      { id: 2, date: '2023-07-26', amount: 89.9, type: 'Top Up' },
      // Add more mock data here
    ];
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Transactions</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionsData.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{transaction.id}</TableCell>
                  <TableCell>{transaction.date}</TableCell>
                  <TableCell>{transaction.amount}</TableCell>
                  <TableCell>{transaction.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Add New Transaction
        </Button>
      </div>
    );
  }
  
  // Prototype component for the "Wallet" tab
  function Wallet() {
    const walletBalance = 2500.0;
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Wallet</Typography>
        <Typography variant="h6">Current Balance: ₱{walletBalance}</Typography>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Add Funds
        </Button>
      </div>
    );
  }
  
  // Prototype component for the "Point of Sale" tab
  function PointOfSale() {
    const posChartData = [
      { day: 'Mon', sales: 15 },
      { day: 'Tue', sales: 10 },
      { day: 'Wed', sales: 20 },
      { day: 'Thu', sales: 25 },
      { day: 'Fri', sales: 18 },
      { day: 'Sat', sales: 30 },
      { day: 'Sun', sales: 22 },
    ];
  
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Point of Sale</Typography>
        <div>
          <LineChart width={500} height={300} data={posChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="sales" stroke="#8884d8" activeDot={{ r: 8 }} />
          </LineChart>
        </div>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Process Sale
        </Button>
      </div>
    );
  }
  
  // Prototype component for the "Settings"
  function Settings() {
    return (
      <div>
        <Typography fontWeight="bold" fontSize={25} sx={{ textAlign: "center", width: "100%" }}>Settings</Typography>
        <Typography>
          Welcome to the settings page! Here you can customize your application's preferences and configurations.
        </Typography>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Edit Profile
        </Button>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Change Password
        </Button>
        <Button variant="contained" color="primary" sx={{ marginTop: '1em' }}>
          Update Notifications
        </Button>
      </div>
    );
  }

    return (
        <Layout>
            <div className="container">
                <div className="hv-100 d-flex align-items-center justify-content-center login__container">
                    <div className={`${loggedIn ? 'w-30' : 'w-50'} card_login-content`}>
                    {!loggedIn ?
                    <>
                        <h2 className="text-center">Welcome to  <br /> Vortex!</h2>
                        <Link href="/">
                            <img src={spark} className="img-fluid spark_img" alt="sparkles" draggable="false" />
                        </Link>
                    </>
                    :
                    <>
                    <div id="navbarNavAltMarkup">
                        {/* add margin top on the list  */}

                    <ul className="navbar-nav" style={{marginTop:"10em"}}>
      <li className="nav-item">
        <span className="nav-link">Unnamed Business</span>
      </li>
      <li className="nav-item">
        <span className="nav-link">Menu <CategoryIcon /></span>
        <ul className="sub-menu">
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'home' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('home')}>
              <HomeIcon /> Home
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'orders' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('orders')}>
              <ReceiptIcon /> Orders
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'products' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('products')}>
              <ShoppingBasketIcon /> Products
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'customers' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('customers')}>
              <PeopleAltIcon /> Customers
            </a>
          </li>
          <li className="nav-item">
          <a className={`nav-link ${activeTab === 'transactions' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('transactions')}>

            <SwapHorizIcon /> Transactions 
            </a>
            <ul className="sub-menu">
              <li className="nav-item">
                <a className={`nav-link ${activeTab === 'walletPayouts' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('walletPayouts')}>
                  <AccountBalanceWalletIcon /> Wallet
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link ${activeTab === 'developers' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('developers')}>
                  <DeveloperModeIcon /> Developers
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li className="nav-item">
        <span className="nav-link">Sales Channels <CategoryIcon /></span>
        <ul className="sub-menu">
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'onlineStore' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('onlineStore')}>
              <StorefrontIcon /> Online Store
            </a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${activeTab === 'pointOfSale' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('pointOfSale')}>
              <ShoppingBasketIcon /> Point of Sale
            </a>
          </li>
        </ul>
      </li>
      <li className="nav-item"> 
          <IOSSwitch/> View live data
      </li>
      <li className="nav-item">
        <a className={`nav-link ${activeTab === 'securityLogs' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('securityLogs')}>
          <SecurityIcon /> Security logs
        </a>
      </li>
      <li className="nav-item">
        <a className={`nav-link ${activeTab === 'settings' ? 'active' : ''}`} href="#" onClick={() => setActiveTab('settings')}>
          <SettingsIcon /> Settings
        </a>
      </li>

      {/* Render the SIGN OUT button if the user is logged in */}
      {loggedIn && (
        <Button onClick={handleSignOut} variant="contained" type="submit" sx={{ height: 50, width: "100%", marginTop: "0em" }}>
          SIGN OUT
        </Button>
      )}
    </ul>
                        </div>
                    </>
                    }
                    </div>
                    <div className={`${loggedIn ? 'h-80 m-10' : 'w-50 h-50 m-50'} d-flex align-items-center justify-content-center`}>
                        <Card elevation={10} sx={{ minWidth: 300, maxWidth: 800, width: 800, marginTop: 20, paddingBottom: 5 }}>
                            <CardContent sx={{ padding: 5 }}>
                                {loggedIn ?
                                  <>

                                  {activeTab === "onlineStore" && (
                                    <div>
                                  <Typography fontWeight={"bold"} fontSize={25} sx={{textAlign: "center", width:"100%"}}>
                                    Store</Typography>
                                  
                                  {/* div that has 3 columns */}
                                  <div className="row">

                                    <div className="col-md-4">
                                        <h6>Primary</h6>
                                        <SketchPicker
                                            color={colorPrimary}
                                            onChange={(color) => setColorPrimary(color.hex)}
                                        />
                                    </div>
                                 
                                 {/* div margin on left and right */}
                                    <div className="col-md-4">
                                        <h6>Secondary</h6>
                                        <SketchPicker
                                            color={colorSecondary}
                                            onChange={(color) => setColorSecondary(color.hex)}
                                        />
                                    </div>

                                    <div className="col-md-4">
                                        <h6>Logo</h6>
                                        {/* img width small */}
        
                                        <img src={logo} className="img-fluid" alt="logo"/>
                                        {/* input string */}
                                        <input type="text" className="form-control" placeholder="Enter logo url" onChange={(e) => setLogo(e.target.value)} value={logo}/>
                                    </div>
                                 </div>

                                 <Button
        variant="contained"
        color="primary"
        sx={{ height: 50, width: "100%", marginTop: "1em", opacity: 1 }}
        onClick={handleClick}
      >
        Update Store
      </Button>

      {submitMessage && (
        <div style={{ marginTop: '1em' }}>
          Store Updated: <span>{submitMessage}</span>
        </div>
      )}

<Link href="https://webstore-vaas.pages.dev" target="_blank" rel="noopener noreferrer" underline="none" >
        <Button variant="contained" color="primary" endIcon={<OpenInNewIcon />} sx={{ height: 50, width: "100%", marginTop: "1em", opacity:0.76 }}> 
            Go to Store
        </Button>
    </Link>
                                    </div>
                                    )}
  {activeTab === 'salesChannels' && <SalesChannels />}
    {activeTab === 'viewLiveData' && <ViewLiveData />}
    {activeTab === 'securityLogs' && <SecurityLogs />}
    {activeTab === 'settings' && <Settings />}
    {activeTab === 'orders' && <Orders />}
    {activeTab === 'products' && <Products />}
    {activeTab === 'customers' && <Customers />}
    {activeTab === 'pointOfSale' && <PointOfSale />}
    {activeTab === 'transactions' && <Transactions />}
    {activeTab === 'walletPayouts' && <Wallet />}
    {activeTab === 'home' && <Home />}

{activeTab === "developers" && (
                                    <div>
                                  <Typography fontWeight={"bold"} fontSize={25} sx={{textAlign: "center", width:"100%"}}>API Credentials</Typography>
                                    {/* <h2></h2> */}
                                    {/* <p>Staging</p> */}
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Staging Client Key"
                                        defaultValue="Tw9K28BRxBrDGGg0pRgPiKT8rVYa"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: "100%"}}
                                        variant="filled"
                                        />
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Staging Secret Key"
                                        defaultValue="1Yp7ftinlsiFuY_1vSCBOJl4zrYa"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: "100%"}}
                                        variant="filled"
                                        />
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Production Client Key"
                                        defaultValue="zkgebj9slaf3yojw8cgk"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: "100%", marginTop: "1em"}}
                                        variant="filled"
                                        />
                                    <TextField
                                        id="filled-read-only-input"
                                        label="Production Secret Key"
                                        defaultValue="ysdcwh57ydkj3toqsq5mbk93qnafp7bib5r4fouz"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        sx={{ width: "100%"}}
                                        variant="filled"
                                        />

<Link href="/documentation" target="_blank" rel="noopener noreferrer" underline="none" >
        <Button variant="contained" color="primary" endIcon={<OpenInNewIcon />} sx={{ height: 50, width: "100%", marginTop: "1em", opacity:0.76 }}> 
            Documentation
        </Button>
    </Link>
                                    </div>
                                    )}
                                    {/* <h2>Documentation:</h2>
                                    <p>{documentation}</p> */}
                                    {/* <Button onClick={() => setLoggedIn(false)} variant={"contained"} type={"submit"} sx={{ height: 50, width: "100%", marginTop:"5em" }}>SIGN OUT</Button> */}
                                    {/* <button onClick={() => setLoggedIn(false)}>Sign Out</button> */}
                                  </> : 
                                  signInForm()
                                }
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Signin;



