import { API } from "../../../config";

export const searchUsers = async (name) => {
  const { token } = JSON.parse(localStorage.getItem("jwt"));
  return await fetch(
    `${process.env.REACT_APP_API_URL ? `${API}` : "/api"}/user/search/byname`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name }),
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to perform search")
      }
      return response.json();
    })
    .catch((err) => {
      //console.log(err)
      throw err;
    });
};


export const getUserById = async (userId) => {
  const { token } = JSON.parse(localStorage.getItem("jwt"));
  return await fetch(
    `${process.env.REACT_APP_API_URL ? `${API}` : "/api"}/user/byid/${userId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to retrieve user")
      }
      return response.json();
    })
    .catch((err) => {
      //console.log(err)
      throw err;
    });
};


export const getUserByRole = async ({ roleNumber }) => {
  const { token } = JSON.parse(localStorage.getItem("jwt"));
  return await fetch(
    `${process.env.REACT_APP_API_URL}/user/list/role/${roleNumber}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to retrieve users")
      }
      return response.json();
    })
    .catch((err) => {
      //console.log(err)
      throw err;
    });
};



export const searchCashiers = async (name) => {
  const { token } = JSON.parse(localStorage.getItem("jwt"));
  return await fetch(
    `${process.env.REACT_APP_API_URL}/user/search/cashier/byname`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ name }),
    }
  )
    .then((response) => {
      if (response.status !== 200) {
        throw Error("Unable to perform search")
      }
      return response.json();
    })
    .catch((err) => {
      //console.log(err)
      throw err;
    });
};


export const updateUser = async ({ userId, updateData }) => {
  const { token } = JSON.parse(localStorage.getItem("jwt"));
  return await fetch(
    `${process.env.REACT_APP_API_URL}/user/update/${userId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateData),
    }
  )
    .then(async (response) => {
      if (response.status !== 200) {
        let jsonData = await response.json()
        throw Error(JSON.stringify(jsonData))
      }
      return response.json();
    })
    .catch((err) => {
      //console.log(err)
      throw err;
    });
}