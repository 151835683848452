import React, { useState, useEffect, useContext } from "react";
import Layout from "../core/Layout";
import { isAuthenticated } from "../auth";
import { Link } from "react-router-dom";
import Jumbotron from "./../core/Jumbotron";
import { Alert, Button, Card, CardContent, Collapse, IconButton, Stack } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { API } from '../config';
import { io } from "socket.io-client";
import CollapseAlert from "../core/CollapseAlert";
import useEditPlatformVariables from "../hook-components/useEditPlatformVariables/useEditPlatformVariables";
import socket from "../services/socketio"
import { PlatformVariables } from "../context/DashboardContext/DashboardContext";
import ValueDisplayCard from "../core/ValueDisplayCard";
import DataTable from "react-data-table-component";


const AdminDashboard = () => {
  const {
    user: { _id, name, email, role },
  } = isAuthenticated();



  const [data, setdata] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(true)

  const { showEditPlatformVariables, closeEditPlatformVariables, EditPlatformVariables } = useEditPlatformVariables()




  const AdminLinks = () => {
    return (
      <>
        {role === 99 ? (
          <div className='card mb-5'>
            <p className='card-header'>Administrator</p>
            <ul className='list-group'>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/stores'>
                  Stores
                </Link>
              </li>
              {/* <li className='list-group-item'>
                <Link className='nav-link' to='/signup'>
                  Add Cashier
                </Link>
              </li> */}
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/userdirectory'>
                  Cashier Directory
                </Link>
              </li>
              <li className='list-group-item' >
                <Link className='nav-link' >
                  Customer Directory
                </Link>
              </li>
              {/* <li className='list-group-item'>
                <Link className='nav-link' to='/admin/backoffice'>
                  Back office
                </Link>
              </li>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/orderdelivery'>
                  Order delivery
                </Link>
              </li> */}
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/vortex'>
                  Vortex
                </Link>
              </li>

              <li className='list-group-item'>
                <a className='nav-link' href='https://www.myvortex1.com/login' target="_blank">
                  Vortex Wallet
                </a>
              </li>
              {/* <li className='list-group-item'>
                <Link className='nav-link' to='/admin/vouchers'>
                  Sparkle Vouchers
                </Link>
              </li>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/spark-express-vouchers'>
                  Spark Express Parcel Vouchers
                </Link>
              </li>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/pasuyo-vouchers'>
                  Spark Express Pasuyo Vouchers
                </Link>
              </li>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/orders'>
                  View Orders
                </Link>
              </li> */}
              {/* <li className='list-group-item'>
                <Link className='nav-link' to='/admin/broadcasts'>
                  Broadcast
                </Link>
              </li> */}
            </ul>
          </div>
        ) : (
          <></>
        )}

        {role === 1 ? (
          <div className='card'>
            <p className='card-header'>Merchant Links</p>
            <ul className='list-group'>
              <li className='list-group-item'>
                <Link className='nav-link' to='/create/category'>
                  Create Category
                </Link>
              </li>
              {/* <li className="list-group-item">
                                <Link className="nav-link" to="/create/product">
                                    Create Product
                                </Link>
                            </li> */}

              {/* <li className="list-group-item">
                                <Link className="nav-link" to="/admin/products">
                                    Manage Products
                                </Link>
                            </li> */}
            </ul>
          </div>
        ) : (
          <></>
        )}

        {role === 2 ? (
          <div className='card mt-5'>
            <p className='card-header'>Coop</p>
            <ul className='list-group'>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/create/coop/category'>
                  Create Category
                </Link>
              </li>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/create/coop/product'>
                  Create Product
                </Link>
              </li>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/coop/orders'>
                  View Orders
                </Link>
              </li>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/coop/products'>
                  Manage Products
                </Link>
              </li>
              <li className='list-group-item'>
                <Link className='nav-link' to='/admin/coop/category'>
                  Update Category Brands
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const AdminInfo = () => {

    const [platformVariables, setPlatformVariables] = useContext(PlatformVariables)

    useEffect(() => {

      fetch(`${API}/auth/platform-variables`, {
        method: "GET",
      })
        .then((response) => {
          return response
        })
        .catch((err) => {

          return err
        })

        .then((response) => {
          if (response?.status === 200) {
            response.json()
              .then(result => {
                console.log(result[0])
                setPlatformVariables(result[0])
                setIsLoading(false)
              })
          } else {
            setIsLoading(false)
            throw Error("Failed getting platform variables")
          }
        })
        .catch((error) => {
          setIsLoading(false)
          throw error
        })


    }, [])

    const [alertState, setAlertState] = useState({
      open: false,
      severity: "success",
      message: "We want to tell you something"
    })


    return (
      <>
        {/* <div className='card mb-5'>
          <h3 className='card-header'>Conversion/Convenience Fee Config</h3>
          <CollapseAlert
            open={alertState.open}
            message={alertState.message}
            onClose={() => {
              setAlertState((prevState) => ({
                ...prevState,
                open: false,
                severity: "warning",
                message: "You need higher access"
              }))
            }}
          />
          <ul className='list-group'>
            <DataTable />
          </ul>
        </div> */}
        <div className='card mb-5'>
          <h3 className='card-header'>User Information</h3>
          <ul className='list-group'>
            <li className='list-group-item'>{name}</li>
            <li className='list-group-item'>{email}   {role === 99 ? "Sandbox Admin" : ""}</li>
          </ul>
        </div>
      </>
    );
  };

  return (
    <Layout>
      <div className=''>
        <Jumbotron title='Dashboard' description={`Sparkling day ${name}!`} />
      </div>
      <div className='container'>
        <div className='row'>
          <div className='col-3'>
            <AdminLinks />
          </div>
          <div className='col-9'>
            <AdminInfo />
          </div>
          <EditPlatformVariables />
        </div>
      </div>
    </Layout>
  );
};

export default AdminDashboard;
